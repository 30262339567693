import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'blog-custom-card',
  templateUrl: './blog-custom-card.component.html',
  styleUrls: ['./blog-custom-card.component.scss']
})
export class BlogCustomCardComponent implements OnInit {

  @Input('data') data: any;

  constructor() { }

  ngOnInit(): void {

  }

  // searchCategory(id) {
  //   let category = this.categories.find(i => i.id == id);
  //   return category.name;
  // }

  readingTime(desc, content) {
    const wpm = 225;
    const wordsDesc = desc.trim().split(/\s+/).length;
    const wordsContent = content.trim().split(/\s+/).length;
    const time = Math.ceil((wordsDesc+wordsContent) / wpm);

    return time;
  }

}
