import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GoogleMapsService } from 'src/app/core/services/google-maps/google-maps.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Output() onSelectedCountry = new EventEmitter<any>();

  showOptions: boolean = false;
  selectedOption: any = '';

  cities: any = [
    { value: 'nearby', name: 'Nearby Location' },
    { value: 'all', name: 'By Keywords' }
  ];
  isNearby: boolean = true;

  searchText: any = '';

  constructor(
    private googleMaps: GoogleMapsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectedOption = this.cities[0];
    this.onSelectedCountry.emit(this.selectedOption);

    this.googleMaps.getLocation();
    this.getLocation();
  }

  showOptionsToggle(country?) {
    this.showOptions = !this.showOptions;

    if(country) {
      let selected = country;
      this.selectedOption = selected;
      this.onSelectedCountry.emit(this.selectedOption);
      if (country.value === 'nearby') {
        this.isNearby = true
      } else {
        this.isNearby = false
      }
    }
  }

  getLocation() {
    this.googleMaps.location.subscribe(res => {
      let city = res.city;
      this.cities.push({ name: city });
      this.selectedOption = this.cities[0];

      // sending data to parent
      this.onSelectedCountry.emit(this.selectedOption);
    })
  }

  searchPlaces() {
    // this.router.navigateByUrl("/search");
    this.router.navigate([`/search`],
      {
        state: {
          searchText: this.searchText,
          isNearby: this.isNearby,
          fromSearch: true
        }
      })
  }

  searchAll() {
    // this.router.navigateByUrl("/search");
    this.router.navigate([`/search`],
      {
        state: {
          searchText: this.searchText,
          isNearby: false,
          fromSearch: true
        }
      })
  }

  searchNearby() {
    // this.router.navigateByUrl("/search");
    this.router.navigate([`/search`],
      {
        state: {
          searchText: this.searchText,
          isNearby: true,
          fromSearch: true
        }
      })
  }

}
