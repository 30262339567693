import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  collections: any = {
    categories_table: "categories",
    users_table: "users",
    topics_table: "topics",
    pages_table: "pages",
    blogs_table: "blogs",
    comments_table: "comments",
    sliders_table: "sliders",
    socials_table: "socials",
    contentPages: {
      about: "about",
      privacyPolicy: "privacy-policy",
      termsConditions: "terms-conditions",
      faq: "faq",
      partnerWithUs: "partner-with-us",
      subscribeForNews: "subscribe-for-news"
    }
  }

  topics: any = [
    {
      id: "W5LhETk0EIkNN8xuPw8J",
      name: "Local Services",
      nameId: "local-services",
      nameDescId: "nearest-local-services"
    },
    {
      id: "TaqWu5ZOFT90NEvUuR1G",
      name: "Things to do",
      nameId: "things-to-do",
      nameDescId: "nearest-things-to-do"
    },
    {
      id: "k1MZpTKlPFn2SDaoMidV",
      name: "My Events",
      nameId: "my-events",
      nameDescId: "nearest-events",
    },
    {
      id: "HVkgQssasz84n8g3cfeU",
      name: "My Shops",
      nameId: "my-shops",
      nameDescId: "nearest-shops"
    },
    {
      id: "bcDkmcOoIx3ehue4XdGl",
      name: "My Places",
      nameId: "my-places",
      nameDescId: "nearest-places"
    },
    {
      id: "XQWKnGCaCbEp9JbV2kZx",
      name: "Other",
      nameId: "other",
      nameDescId: "other"
    }
  ]

  myShopsTopicId: any = 'HVkgQssasz84n8g3cfeU';
  eventTopicId: any = 'k1MZpTKlPFn2SDaoMidV';

  constructor() { }
}
