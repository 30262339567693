<div class="search-bar ">
  <div class="row">

    <div class="col-12 col-md-12 col-lg-8">
      <div class="search-input">
        <input type="text" [(ngModel)]="searchText" placeholder="{{ 'search-you-want' | translate }}" (keydown.enter)="searchPlaces()">
      </div>
    </div>

    <!-- <div class="col-9 col-md-10 col-lg-4">
      <div class="custom-dropdown">
        <p (click)="showOptionsToggle()">
          <i class="fas fa-map-marker-alt text-pinkgradient"></i>
          <span class="ml-3">{{selectedOption?.name | translate}}</span>
          <i class="fas arrow" [ngClass]="{'fa-chevron-up': showOptions, 'fa-chevron-down': !showOptions}"></i>
        </p>
        <div class="options mt-2" [ngClass]="{'show': showOptions}">
          <ul>
            <li (click)="showOptionsToggle(city)" *ngFor="let city of cities">{{city.name | translate}}</li>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="col-6 col-md-6 col-lg-2 change">
      <div class="search-icon">
        <button (click)="searchNearby()"><span class="mini-search"><i class="fas fa-search"></i></span><span class="mini-view nearby-label">{{ 'search-nearby' | translate }}</span></button>
      </div>
    </div>

    <div class="col-6 col-md-6 col-lg-2 change">
      <div class="search-icon">
        <button (click)="searchAll()"><span class="mini-search"><i class="fas fa-search"></i></span><span class="mini-view">{{ 'search-all' | translate }}</span></button>
      </div>
    </div>

  </div>
</div>
