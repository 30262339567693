<div class="add-img">
  <div style="position: relative" class="img-upload-tile">
    <span class="btn-remove-img">X</span>
    <!-- <img [src]="imgBypass(file.url)" class="img-preview" alt="Preview Image"> -->
    <input class="d-none" type="file" accept="image/*" #file (change)="readURL($event)">
    <a (click)="file.click()">
      <img *ngIf="!imageUploaded" src={{preview}} alt="Preview Image" title="Preview Image">
      <img *ngIf="imageUploaded" src={{loading}} alt="Preview Image" title="Preview Image">
    </a>
  </div>
</div>