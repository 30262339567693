import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/http/config/config.service'
import { AngularFirestore } from '@angular/fire/firestore';
import { LoaderService } from '../../services/loader/loader.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private config: ConfigService,
    private firestore: AngularFirestore,
    private loader: LoaderService
  ) { }

  post(collection, data) {
    let uid = this.firestore.createId()
    if (data != null) {
      data.id = uid
    }
    console.log("post doc. ", data)
    return this.firestore.collection(collection).doc(uid).set(data);
    // return this.firestore.collection(collection).doc().set(data);
  }

  getAll(collection) {
    let data = [];

    this.firestore.collection(collection).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data() as {}
        });
      });
    });

    return new Observable((observer) => {
      observer.next(data);
    });
  }

  getSingle(collection, id) {
    let data = {};

    return new Observable((observer) => {
      this.firestore.collection(collection).doc(id).get().subscribe(res => {
        data = {
          id: res.id,
          ...res.data() as {}
        }

        observer.next(data);
      })
    });
  }

  put(collection, id, data) {
    return this.firestore.collection(collection).doc(id).update(data);
  }

  delete(collection, id) {
    return this.firestore.collection(collection).doc(id).delete();
  }

  updateView(collection, id) {
    return this.firestore.collection(collection).doc(id).update({ 
      viewsCount: firebase.default.firestore.FieldValue.increment(1)
    });
  }

  getWithQuery(collection, key, operator, matchingValue) {
    let data = [];
    return new Observable((observer) => {
      this.firestore.collection(collection, ref => ref.where(key, operator, matchingValue)).get().subscribe(res => {
        res.forEach(i => {
          data.push({
            id: i.id,
            ...i.data() as {}
          })
        });
        observer.next(data);
      })
    });
  }

  getPageDetail(collection, key, operator, matchingValue) {
    let data = [];
    return new Observable((observer) => {
      this.firestore.collection(collection, ref => ref.where(key, operator, matchingValue)).get().subscribe(res => {
        res.forEach(i => {
          data.push({
            id: i.id,
            ...i.data() as {}
          })
        });

        observer.next(data);
      })
    });
  }

  getAllPageList(collection) {
    let data = [];

    this.firestore.collection(collection).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data() as {}
        });
      });
    });

    return new Observable((observer) => {
      observer.next(data);
    });
  }

  
  // getAll(collection) {
  //   let data = [];

  //   this.firestore.collection(collection).get().subscribe((res) => {
  //     res.docs.forEach((doc) => {
  //       data.push({
  //         id: doc.id,
  //         ...doc.data() as {}
  //       });
  //     });
  //   });

  //   return new Observable((observer) => {
  //     observer.next(data);
  //   });
  // }
  
  
  // this.firestore.collection<User>('users', ref => { return ref.orderBy('name').startAt(name).endAt(name+'\uf8ff') }).snapshotChanges();

  getPageSearch(collection, key, operator, keyword) {
    let data = [];
    // console.log("Search: ", collection, key, operator, keyword)
    this.firestore.collection(collection, ref => {
      return ref.orderBy('title')
        .startAt(keyword)
        .endAt(keyword + '\uf8ff')
        // .limit(3)
    }).get().subscribe(res => {
      res.forEach(i => {
        data.push({
          id: i.id,
          ...i.data() as {}
        });
      });
    });  
    return new Observable((observer) => {
      observer.next(data);
    });
  }

  formatDataListing(res) {
    let data = Object.entries(res);
    let formatted = [];  

    data.forEach(item => {
      formatted.push({
        Id: item[0],
        ...item[1] as {}
      })
    });

    return formatted.reverse();
  }

  updateProfile(collection, key, operator, matchingValue, avatarUrl) {
    let data = [];
    return new Observable((observer) => {
      this.firestore.collection(collection, ref => ref.where(key, operator, matchingValue))
      .get().subscribe(res => {
        res.forEach(i => {
          i.ref.update({'created_by.avatar': avatarUrl})
        });
        observer.next(data);
      })
    });
  }
}
