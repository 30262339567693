import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomCardComponent } from './custom-card/custom-card.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LoaderComponent } from './loader/loader.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { SocialShareComponent } from './social-share/social-share.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductImageUploaderComponent } from './product-image-uploader/product-image-uploader.component';
import { FormsModule } from '@angular/forms';
import { ByPassSecurityPipe } from './function/sanitize.pipe';
import { BlogCustomCardComponent } from './blog-custom-card/blog-custom-card.component';

@NgModule({
  declarations: [
    CustomCardComponent,
    SearchBarComponent,
    LoaderComponent,
    ImageUploaderComponent,
    SocialShareComponent,
    ProductImageUploaderComponent,
    ByPassSecurityPipe,
    BlogCustomCardComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    CustomCardComponent,
    SearchBarComponent,
    LoaderComponent,
    ImageUploaderComponent,
    SocialShareComponent,
    ProductImageUploaderComponent,
    ByPassSecurityPipe,
    BlogCustomCardComponent
  ]
})
export class SharedModule { }
