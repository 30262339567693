<div class="custom-card">
  <div class="img-section" style="background-image: url({{data.pageImages[0].url}});">
  <!-- <div class="img-section" style="background-image: url({{data.image}});"> -->
    <!-- <div class="icon-bar">
      <div class="row">
        <div class="col-8">
          <p>
            <i class="fas fa-user"></i>
            {{data.created_by.fullName}}
          </p>
        </div>
        <div class="col-6">
          <p class="amount">{{data.price}}</p>
        </div>
      </div>
    </div> -->
  </div>
  <div class="content-section">
    <div class="row">
      <div class="col-12">
        <div class="title">
          <p class="text-truncate" style="white-space:unset !important">
            <i *ngIf="data.featured" class="fas fa-star"></i>
            {{ data.title }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="view-label" *ngIf="data.viewsCount !== undefined else noViews">{{ data.viewsCount }} views</p>
        <ng-template #noViews>
          <p class="view-label">0 views</p>
        </ng-template>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="title">
          <p class="text-truncate" style="white-space:unset !important">
            <i *ngIf="data.featured" class="fas fa-star"></i>
            {{data.title}}
          </p>
        </div>
      </div>
    </div> -->

    <!-- <div class="day-time">
      <div class="row">
        <div class="col-6" [ngClass]="{'col-12': data.everyDay}">
          <span class="icon"><i class="far fa-calendar-alt"></i></span>
          <span class="text" *ngIf="!data.everyDay">{{data.startDateTime | date: 'longDate'}}</span>
          <span class="text" *ngIf="data.everyDay && data.extraAvailability">{{data.extraAvailability}}</span>
          <span *ngIf="data.everyDay && !data.extraAvailability">Not Specified</span>
        </div>
        <div class="col-6" *ngIf="!data.everyDay">
          <span class="icon"><i class="far fa-clock"></i></span>
          <span class="text">{{data.startDateTime | date: 'shortTime'}}</span>
        </div>
      </div>
    </div> -->
  </div>
</div>
