import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../core/services/loader/loader.service'
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  @Input('isReset') isReset: boolean = false;
  @Input('ifPreviewAvailable') ifPreviewAvailable: any = "";
  
  @Output() previewImage = new EventEmitter<any>();

  preview: any = "assets/img/img-upload-icon.png";
  percent: number = 0;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  imageUploaded: boolean = false;

  constructor(
    private storage: AngularFireStorage,
    public loader: LoaderService
  ) { }

  ngOnInit(): void {
    if (this.ifPreviewAvailable && this.ifPreviewAvailable != "0") this.preview = this.ifPreviewAvailable;
  }

  ngOnChanges() {
    if (this.isReset) {
      this.resetPreview();
    }
    if (this.ifPreviewAvailable && this.ifPreviewAvailable != "0") this.preview = this.ifPreviewAvailable;
  }

  resetPreview(): void {
    this.preview = "assets/img/img-upload-icon.png";
    console.log("this.storage: ", this.storage.ref)
  }

  uploadFile(event) {
    this.imageUploaded = !this.imageUploaded;
    this.loader.isLoading.next(true);

    const file = event.target.files[0];
    // const filePath = file.name;
    // const fileRef = this.storage.ref(filePath);
    // const task = this.storage.upload(filePath, file);

    const ext = file.name.split('.').pop();
    const filePath = `${uuidv4()}.${ext}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    
    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    this.uploadPercent.subscribe(res => this.percent = res );

    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(res => {
          this.preview = res;
          this.imageUploaded = !this.imageUploaded;

          // send status to parent
          this.previewImage.emit({
            preview: this.preview,
            imageUploaded: this.imageUploaded
          })

          // stop loading
          this.loader.isLoading.next(false);
          console.log("downloadURL res: ", res)
        });
      })
    )
    .subscribe();

    console.log("this.preview on sub: ", this.preview)
    console.log("ifPreviewAvailable: ", this.ifPreviewAvailable)

    
  }

  readURL(event: Event): void {
    if (this.preview !== 'assets/img/img-upload-icon.png') {
      this.deleteImg();
      return;
    } else {
      if (event.target['files'] && event.target['files'][0]) {
        const file = event.target['files'][0];
        const reader = new FileReader();
  
        // check image size should be <= 1000KB
        let imageSizeInKbs = file.size/1024;
  
        if(imageSizeInKbs <= 1000){
          // render image preview in image viewer area
          reader.onload = e => this.preview = reader.result;
          reader.readAsDataURL(file);
  
          // store image in DB and get store URL
          this.uploadFile(event);
        } else {
          alert('Image should be less than 1000KB, reduce your image size using https://tinypng.com/');
        }
      }
    }
    this.toggleDelete();
  }

  deleteImg() {
    console.log("this.preview on delete: ", this.preview)

    if (confirm('Are you sure you want to delete this images?')) {
      console.log("delete: ", this.preview)
      let del = this.storage.refFromURL(this.preview).delete();
      this.resetPreview();
      // this.ifPreviewAvailable = '0'
      // this.preview = '0'
      // this.previewImage.emit({
      //   preview: '0',
      //   imageUploaded: true
      // })
      console.log("this.previewImage: ", this.previewImage)
    }
  }

  toggleDelete() {
    if (this.preview === 'assets/img/img-upload-icon.png') {
      return false;
    } else {
      return true;
    }
  }

}
