// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google_maps_api: 'YOUR_GOOGLE_MAPS_API_KEY',
  // google_maps_api: 'AIzaSyBX9egvBp_Ds0bLGWT4ouU-YmuTiczYnEU',
  firebase: {
    apiKey: "AIzaSyBgV7EwK83JrAd_TRzkQGQ3hugIBkjxC0I",
    authDomain: "goloqal-dc7bf.firebaseapp.com",
    projectId: "goloqal-dc7bf",
    storageBucket: "goloqal-dc7bf.appspot.com",
    messagingSenderId: "544164490700",
    appId: "1:544164490700:web:70cdbfd859425311114169",
    measurementId: "G-K2P6VHRHV6"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
