import {Injectable, EventEmitter} from "@angular/core";    

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
onGetCurrentLanguage = new EventEmitter();

getCurrentLanguage() {
    this.onGetCurrentLanguage.emit('');
  }
}