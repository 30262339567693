<div class="add-img">
  <input class="d-none" type="file" accept="image/*" #file (change)="readURL($event)">
  <a (click)="file.click()">
    <!-- <a (click)="selectFile($event)"> -->
      <img *ngIf="!imageUploaded" src={{preview}} alt="Preview Image" title="Preview Image">
      <img *ngIf="imageUploaded" src={{loading}} alt="Preview Image" title="Preview Image">
    </a>
  <div *ngIf="toggleDelete()" class="delete-img">
    <i class="fas fa-times" (click)="deleteImg()"></i>
  </div>
</div>


