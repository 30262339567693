import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/core/services/login/login.service';
import { LanguageService } from 'src/app/shared/language-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logo: string = "";

  toggleButton: boolean = false;
  sidebar: boolean = false;
  fadeSection: boolean = false;

  user: any = {};
  homeUrl = '';
  isHome: boolean = false;

  selectedCountryCode = 'th';
  countryCodes = ['us', 'th'];

  isMenuOpened: boolean = false;

  constructor(
    private loginSrv: LoginService,
    private router: Router,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {
    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.homeUrl = route.url;
        if (this.homeUrl && this.homeUrl.length > 0) {
          this.homeUrl = this.homeUrl.slice(1);
        }
        if (this.homeUrl === '' || this.homeUrl == '/' || this.homeUrl == 'homepage') {
          this.isHome = true;
        } else {
          this.isHome = false
        }
      }
    });
  }

  ngOnInit(): void {
    this.getUserData();
  }

  toggleMenuList() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  clickedOutside() {
    this.isMenuOpened = false;
  }

  toggleMenu() {
    // animating icon
    this.toggleButton = !this.toggleButton;

    // animating sidebar
    this.sidebar = !this.sidebar;

    // enabling fade section
    this.fadeSection = !this.fadeSection;
  }

  getUserData() {
    this.user = this.loginSrv.getUserData();
  }

  logout() {
    this.loginSrv.logout();
    this.router.navigateByUrl('/homepage');

    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
    value == "us" ? value = "en-US" : value = "th-TH"
    this.translateService.use(value);
    this.languageService.onGetCurrentLanguage.emit(value);
  }

}
