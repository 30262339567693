import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  SwPush,
  SwUpdate,
  UnrecoverableStateEvent,
  VersionEvent,
  VersionReadyEvent,
} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'goloqal-app';
  url = '';
  constructor(
    private router: Router, 
    private translateService: TranslateService,
    private updateService: SwUpdate,
    private pushService: SwPush,
) {
       router.events.subscribe((route) => {
       if(route instanceof NavigationEnd){
          this.url = route.url;
          if(this.url && this.url.length > 0){
            this.url = this.url.slice(1);
          }
       }
    });
  }

  ngOnInit() {
    if (!this.updateService.isEnabled) {
      console.log('AppComponent.ngOnInit: Service Worker is not enabled');
      return;
    }
    console.log('AppComponent.ngOnInit: Service Worker is enabled');
  }

  public selectLanguage(event: any) {
    this.translateService.use(event.target.value);
  }

}
