<div [ngClass]="isHome? 'header': 'header-secondary'">
  <div class="container">
      <div class="row align-items-center" >
        <div class="col-3 col-xs-4 col-sm-4 col-md-2 " [ngClass]="isHome? 'logo-round': 'logo-round'">
          <a class="logo cursor-pointer" routerLink="/">
            <img src="../../../assets/img/logo-goloqal.png" class="responsive text-center" alt="Goloqal.co" title="Goloqal.co">
          </a>
        </div>

        <div class="col-9 col-sm-8 col-md-10" [ngClass]="isHome? 'side-menu': 'side-menu'">
          <div [ngClass]="isHome? 'nav-primary': 'nav-primary'">
            <ul>
              <li *ngIf="user?.id" class="upper-menu"><a class="text-uppercase" routerLink="/page/page-listing">{{ 'my-page' | translate }}</a></li>
              <!-- <li *ngIf="user?.id" class="upper-menu"><a class="text-uppercase" routerLink="/for-you">{{ 'for-you' | translate }}</a></li> -->
              <li *ngIf="user?.id" class="upper-menu"><a class="text-uppercase" routerLink="page/create-page">{{ 'create-page' | translate }}</a></li>
              <li *ngIf="!user?.id"><a class="text-uppercase no-wrap-text" routerLink="/auth/login">{{ 'signin' | translate }}</a></li>
              <li *ngIf="!user?.id"><a class="text-uppercase" routerLink="/auth/signup">{{ 'join' | translate }}</a></li>
              <li *ngIf="user?.id" class="user-menu" clickOutside (clickOutside)="clickedOutside()">
                <!-- <div class="user-img" routerLink="/auth/edit-profile">
                  <img src="{{user.avatar}}" alt="user" title="Goloqal.co">
                </div> -->
                <div class="user-img menu-toggler" (click)="toggleMenuList()" *ngIf="user?.id">
                  <img src="{{user.avatar}}" alt="User Profile" title="User Profile">
                  <div class="menu" *ngIf="isMenuOpened">
                    <div class="menu-item" routerLink="/page/page-listing">{{ 'my-page' | translate }}</div>
                    <div class="menu-item" routerLink="/admin" *ngIf="user.user_type == 'admin'">{{ 'admin-controls' | translate }}</div>
                    <div class="menu-item" routerLink="/categories/add-category" *ngIf="user.user_type == 'admin'">{{ 'add-categories' | translate }}</div>
                    <!-- <div class="menu-item">{{ 'create-page' | translate }}</div> -->
                    <div class="menu-item" routerLink="/page/create-page">{{ 'create-page' | translate }}</div>
                    <div class="menu-item" routerLink="/blogs/create-blog" *ngIf="user.user_type == 'admin'">{{ 'write-blog' | translate }}</div>
                    <!-- <div class="menu-item">{{ 'for-you' | translate }}</div> -->
                    <div class="menu-item" routerLink="/auth/edit-profile">{{ 'profile' | translate }}</div>
                    <div class="menu-item" (click)="logout()">{{ 'logout' | translate }}</div>
                  </div>
                </div>         
              </li>
              <li class="user-menu">
                <div [ngClass]="!user?.id ? 'language-picker-area': 'language-login'">
                  <ngx-flag-picker
                  [selectedCountryCode]="selectedCountryCode"
                  [countryCodes]="countryCodes"
                  [showFlags]="true"
                  [showLabels]="false"
                  [showArrow]="false"
                  (changedCountryCode)="changeSelectedCountryCode($event)">
                </ngx-flag-picker>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="sidebar side" [ngClass]="{'show': sidebar}">
  <ul *ngIf="user?.id">
    <li *ngIf="user.user_type == 'admin'">
      <a (click)="toggleMenu()" routerLink="/admin">
        Admin Controls
      </a>
    </li>
    <li>
      <a (click)="toggleMenu()" routerLink="/page/create-page">
        {{ 'create-page' | translate }}
      </a>
    </li>
    <li>
      <a (click)="toggleMenu()" routerLink="/blogs/create-blog">
        Write Blog
      </a>
    </li>
    <li>
      <a (click)="toggleMenu()" routerLink="/for-you">
        {{ 'for-you' | translate }}
      </a>
    </li>
    <li>
      <a (click)="toggleMenu()" routerLink="/categories/add-category">
        Add Category
      </a>
    </li>
    <li>
      <a (click)="toggleMenu()" routerLink="/auth/edit-profile">
        Profile
      </a>
    </li>
    <li style="margin-bottom: 5em">
        <ngx-flag-picker
        [selectedCountryCode]="selectedCountryCode"
        [countryCodes]="countryCodes"
        [showFlags]="true"
        [showLabels]="true"
        [showArrow]="true"
        (changedCountryCode)="changeSelectedCountryCode($event)">
      </ngx-flag-picker>
    </li>
  </ul>

  <div class="press text-center">
    <button class="c-btn pink mr-2" *ngIf="!user?.id" (click)="toggleMenu()" routerLink="/auth/login">Sign In</button>
    <button class="c-btn green" *ngIf="!user?.id" (click)="toggleMenu()" routerLink="/auth/signup">Register</button>
    <button class="c-btn pink" *ngIf="user?.id" (click)="logout()">Logout</button>
  </div>
</div>

<div class="fade-area" [ngClass]="{'show': fadeSection}" (click)="toggleMenu()"></div>
