<div class="custom-card">
    <div class="img-section" style="background-image: url({{data.pageImages[0].url}});">
    </div>
    <div class="content-section">
      <div class="row">
        <div class="col-12">
          <div class="title">
            <p class="text-truncate" style="white-space:unset !important">
              <i *ngIf="data.featured" class="fas fa-star"></i>
              {{data.blogTitle}}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="view-label" *ngIf="data.viewsCount !== undefined else noViews">{{ data.viewsCount }} views</p>
          <ng-template #noViews>
            <p class="view-label">0 views</p>
          </ng-template>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-2">
          <img src="{{data.user.avatar}}" class="user-avatar" alt="Goloqal.co - User Profile" title="Goloqal.co - User Profile">
        </div>
        <div class="col-10">
          <p class="blog-creator">{{ data.user.fullName }}</p>
          <p class="blog-date">{{ data.createdAt | date: 'medium' }}</p>
        </div>
      </div>
    </div>
  </div>
  