<div class="footer">
    <div class="container">
      <div class="footer-top-section">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-4">
                <h3 class="text-pinkgradient">{{ 'company' | translate }}</h3>
                <a routerLink="/about-us">{{ 'about-us' | translate }}</a>
                <a routerLink="/contact-us">{{ 'contact-us' | translate }}</a>
                <a routerLink="/auth/privacy-policy">{{ 'privacy-policy' | translate }}</a>
                <a routerLink="/auth/terms-conditions">{{ 'terms-conditions' | translate }}</a>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
                <h3 class="text-pinkgradient">{{ 'follow-us' | translate }}</h3>
                <a *ngIf="socials[0]?.facebook" href="{{socials[0]?.facebook}}">{{ 'facebook' | translate }}</a>
                <a *ngIf="socials[0]?.twitter" href="{{socials[0]?.twitter}}">{{ 'twitter' | translate }}</a>
                <a *ngIf="socials[0]?.instagram" href="{{socials[0]?.instagram}}">{{ 'instagram' | translate }}</a>
                <a *ngIf="socials[0]?.pinterest" href="{{socials[0]?.pinterest}}">{{ 'pinterest' | translate }}</a>
                <a *ngIf="socials[0]?.line" href="{{socials[0]?.line}}">{{ 'line@' | translate }}</a>
            </div>
            <div class="col-12 col-md-4 col-lg-4">
                <h3 class="text-pinkgradient">{{ 'content' | translate }}</h3>
                <a routerLink="/faq">{{ 'faq' | translate }}</a>
                <a routerLink="/blogs/blog-listing">{{ 'blog' | translate }}</a>
                <a routerLink="/partner-with-us">{{ 'partner-with-us' | translate }}</a>
                <!-- <a routerLink="/subscribe-news">{{ 'subscribe-for-news' | translate }}</a> -->
            </div>
        </div>
      </div>
      <div class="footer-bottom-section">
        <div class="row">
          <div class="col-12">
            <p>Copyright @{{year}} GOLOQAL Co., Ltd. All right reserved.</p>
          </div>
        </div>
      </div>
    </div>
</div>
